import React from 'react'

import { CheckIcon } from '@radix-ui/react-icons'
import 'twin.macro'
import { navigate } from 'gatsby'

import { useHowItWorksPhaseOne } from 'content-queries/mx/how-it-works-phase-1'
import { Parallax } from 'mx/components/parallax'
import { MxButton as Button } from 'mx/components/section-components'
import {
  ShowYourSpace,
  WorkWithYourDesigner,
} from 'mx/how-it-works/phase-sections'
import { Section } from 'mx/section'

import {
  CategoryListText,
  CategoryPrimaryText,
  Container,
  Content,
  Line,
  PhaseCategory,
  PhasePre,
  PhaseTitle,
  Step,
  Stepper,
} from './container'

const PhaseOne = ({ phase }: { phase: number }) => {
  const { data } = useHowItWorksPhaseOne()
  const [
    {
      node: {
        title,
        image,
        meta: {
          expectationDetails,
          method,
          priceDetails: { price, label },
          pre,
        },
        sectionModules: [showYourSpace, workWithYourDesigner],
      },
    },
  ] = data

  return (
    <Section padding="top">
      <Container>
        <Stepper tw="row-span-2">
          <Step>0{phase}</Step>
          <Line />
          <PhasePre>{pre}</PhasePre>
        </Stepper>
        <Content tw="row-span-1 pb-24 pt-8 md:(py-24)">
          <div tw="col-span-full flex flex-col md:(flex-row items-center)">
            <div tw="flex flex-col md:(w-1/2)">
              <div tw="space-y-1">
                <PhaseTitle>{title}</PhaseTitle>
              </div>
              <div tw="h-full relative py-16 px-10 md:(hidden)">
                <Parallax tw="w-full">
                  <img src={image.file.url} alt={image.title} />
                </Parallax>
              </div>
              <div tw="flex flex-row space-y-1 space-x-20 md:(pt-8)">
                <div>
                  <PhaseCategory>{label}</PhaseCategory>
                  <CategoryPrimaryText>{price}</CategoryPrimaryText>
                </div>
                <div>
                  <PhaseCategory>What this is</PhaseCategory>
                  <CategoryPrimaryText>{method}</CategoryPrimaryText>
                </div>
              </div>

              <div tw="space-y-2 py-8">
                <PhaseCategory>{expectationDetails?.label}</PhaseCategory>
                <div tw="space-y-3 text-gray-500 pl-4">
                  {expectationDetails?.expectations.map(
                    (expectation: string, index: number) => (
                      <div tw="flex space-x-2 items-center" key={index}>
                        <div>
                          <CheckIcon />
                        </div>
                        <CategoryListText
                          dangerouslySetInnerHTML={{
                            __html: expectation,
                          }}
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div tw="mx-auto md:m-0">
                <Button
                  color="default"
                  onClick={() => {
                    navigate('/welcome/free-video-call')
                  }}
                >
                  Book
                </Button>
              </div>
            </div>
            <div tw="hidden md:(h-full relative w-1/2 flex items-center justify-center)">
              <Parallax tw="w-10/12">
                <img src={image.file.url} alt={image.title} />
              </Parallax>
            </div>
          </div>
        </Content>
        <Content tw="row-span-1 space-y-20 mb-32 md:(space-y-32) lg:(px-0)">
          <ShowYourSpace content={showYourSpace} />
          <WorkWithYourDesigner content={workWithYourDesigner} />
        </Content>
      </Container>
    </Section>
  )
}

export { PhaseOne }
